import React from "react"
import { createRoot } from 'react-dom/client'
import { Provider } from "react-redux"
import store from "./store"
import Root from "./Root"
import "App.css"

const App = () => {
    return <Root />
}

const AppWrapper = () => {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    )
}

const domNode = document.getElementById("root")
const root = createRoot(domNode)
root.render(<AppWrapper />)