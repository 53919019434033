import { GREEN_CRUSADER_FINISHED, GREEN_CRUSADER_CLEAN } from "../actions/types"

const INIT_STATE = {}

const greenCrusaderReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GREEN_CRUSADER_FINISHED:
            return {
                ...state,
                registerFinished: action.payload.crusader
            }
        case GREEN_CRUSADER_CLEAN:
            return {
                ...state,
                registerFinished: null
            }
        default:
            return state
    }
}

export default greenCrusaderReducer
