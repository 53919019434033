import React from "react"
import { connect } from "react-redux"
import { getEnergyTranisionActions } from "../actions/energyTransitionAction"
import TableWithLoader from "antd-table-loader"
import { Col, Divider, Radio, Row, Skeleton } from "antd"
import ActionDisplayModal from "../components/ActionDisplayModal"
import { IMAGE_NUMBER_TWO } from "../config"


class EnergyTransition extends React.Component {

    constructor(props) {
        super(props)
        this.state = { selectedAction: null, selectedSector: "Awareness-raising" }
    }

    componentDidMount() {
        this.props.getEnergyTranisionActions()
    }

    columns = [
        {
            title: "Sector",
            dataIndex: "sector",
            key: "sector",
            align: "center",
        },
        {
            title: "Actions",
            dataIndex: "description",
            key: "description",
            align: "center",
        },
        // {
        //     title: "AP in Spain",
        //     dataIndex: "ap_in_spain",
        //     key: "ap_in_spain",
        //     align: "center",
        //     sorter: (a, b) => (a.ap_in_spain > b.ap_in_spain ? 1 : -1),
        // },
        // {
        //     title: "AP in Greece",
        //     dataIndex: "ap_in_greece",
        //     key: "ap_in_greece",
        //     align: "center",
        //     sorter: (a, b) => (a.ap_in_greece > b.ap_in_greece ? 1 : -1),
        // },
        // {
        //     title: "AP in Italy",
        //     dataIndex: "ap_in_italy",
        //     key: "ap_in_italy",
        //     align: "center",
        //     sorter: (a, b) => (a.ap_in_italy > b.ap_in_italy ? 1 : -1),
        // },
        // {
        //     title: "Energy savings (MWhh/year)",
        //     dataIndex: "energy_savings",
        //     key: "energy_savings",
        //     align: "center",
        //     sorter: (a, b) => (a.energy_savings > b.energy_savings ? 1 : -1),
        // },
        // {
        //     title: "CO2 savings (tCO2e)",
        //     dataIndex: "co2_savings",
        //     key: "co2_savings",
        //     align: "center",
        //     sorter: (a, b) => (a.co2_savings > b.co2_savings ? 1 : -1),
        // },
        // {
        //     title: "Cost (€)",
        //     dataIndex: "cost",
        //     key: "cost",
        //     align: "center",
        //     sorter: (a, b) => (a.cost > b.cost ? 1 : -1),
        // },
    ]

    getActions = () => {
        return this.props.actions?.filter(action => action.sector == this.state.selectedSector)
    }

    renderSectors = () => {
        return (

            <Radio.Group onChange={(e) => this.setState({ selectedSector: e.target.value })} defaultValue={this.state.selectedSector}>
                <Radio.Button value="Awareness-raising">Awareness-raising</Radio.Button>
                <Radio.Button value="Municipal Buildings and Equipment">Municipal Buildings and Equipment</Radio.Button>
                <Radio.Button value="Transport">Transport</Radio.Button>
            </Radio.Group>

        )
    }

    renderTable = () => {
        return <TableWithLoader
            style={{
                cursor: 'pointer',
                background: `rgba(255, 255, 255, .0)`
            }}
            size={"small"}
            bordered={false}
            loader={{
                rows: 10,
                component: <Skeleton active loading title={false} />,
            }}
            pagination={false}
            columns={this.columns}
            dataSource={this.getActions()}
            rowKey={(record) => record.description}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => this.onActionSelected(record),
                }
            }}
        />
    }

    onActionSelected = action => {
        this.setState({ selectedAction: action })
    }

    render() {
        return (
            <div
                style={{
                    height: '100vh',
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),url(${IMAGE_NUMBER_TWO})`,
                    backgroundSize: 'cover'
                }}
            >
                <Divider>
                    <h1 style={{ color: 'white' }}>
                        Energy Transition
                    </h1>
                </Divider>

                {
                    this.state.selectedAction ?
                        <ActionDisplayModal
                            action={this.state.selectedAction}
                            closeModal={() => this.setState({ selectedAction: null })}
                        />
                        :
                        <></>
                }

                <div style={{ paddingLeft: '20%', paddingRight: '20%', background: `rgba(255, 255, 255, .0)` }}>
                    <Row align={'middle'}>
                        <Col span={24}>
                            {this.renderSectors()}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '36px' }}>
                        <Col span={24}>
                            {this.renderTable()}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        actions: state.energyTransition.actions
    }
}

export default connect(mapStateToProps, { getEnergyTranisionActions })(EnergyTransition)