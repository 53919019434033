import React from "react"
import { getIslandTypologyCharts, getIslandTypologyTypes } from "../actions/islandTypologyAction"
import { connect } from "react-redux"
import { Button, Divider } from "antd"
import FlowchartList from "../components/flowchart/FlowchartList"
import Flowchart from "../components/flowchart/Flowchart"
import FlowchartResult from "../components/flowchart/FlowchartResult"

import { LOGO_FILE, MAIN_SITE_URL, IMAGE_NUMBER_ONE, IMAGE_NUMBER_TWO, IMAGE_NUMBER_THREE } from "../config"

class IslandTypology extends React.Component {

    constructor(props) {
        super(props)

        this.state = { selectedFlowchart: null, resultIslandType: null }
    }

    componentDidMount() {
        this.props.getIslandTypologyCharts()
        this.props.getIslandTypologyTypes()
    }

    renderFlowchartList = () => {
        return (
            <FlowchartList
                charts={this.props.charts}
                onFlowchartSelected={(chart) => this.setState({ selectedFlowchart: chart })}
            />
        )
    }

    renderFlowchart = () => {
        return (
            <Flowchart
                setIslandTypeResult={(islandType => this.setState({ resultIslandType: islandType }))}
                flowchart={this.state.selectedFlowchart} />
        )
    }

    renderFlowchartResult = () => {
        return (
            <FlowchartResult islandType={this.state.resultIslandType} />
        )
    }

    onResetPressed = () => {
        this.setState({ selectedFlowchart: null, resultIslandType: null })
    }

    resetButton = () => {
        return (
            <Button onClick={this.onResetPressed}>
                Back
            </Button>
        )
    }
    render() {
        return (
            <div style={{
                paddingLeft: '5%', paddingRight: '5%',
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),url(${IMAGE_NUMBER_ONE})`,
                backgroundSize: "cover",
                height: '100vh',
            }}>
                <Divider>
                    <h1 style={{ color: 'white' }}>
                        Island Typology
                    </h1>
                </Divider>
                {
                    (this.state.selectedFlowchart || this.state.resultIslandType) ? this.resetButton() : <></>
                }
                {
                    this.state.resultIslandType ?
                        this.renderFlowchartResult()
                        :
                        this.state.selectedFlowchart ?
                            this.renderFlowchart()
                            :
                            this.renderFlowchartList()
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        types: state.islandTypology.types,
        charts: state.islandTypology.charts
    }
}

export default connect(mapStateToProps, { getIslandTypologyCharts, getIslandTypologyTypes })(IslandTypology)