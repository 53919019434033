import { NOTIFICATION_CLEAN, NOTIFICATION_SHOW } from "../actions/types"

const INIT_STATE = {}

const notificationReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NOTIFICATION_SHOW:
            return {
                ...state,
                notification: action.payload.notification,
            }
        case NOTIFICATION_CLEAN:
            return {
                ...state,
                notification: null,
            }
        default:
            return state
    }
}

export default notificationReducer
