import { Card, Col, Divider, Row, Tag } from "antd"
import React from "react"



class FlowchartResult extends React.Component {

    renderIslandType = () => {
        return (
            <Row gutter={[20, 20]}>
                <Col style={{ display: "flex", flexDirection: "column" }} span={12}>
                    <Card style={{ flex: 1, background: `rgba(255, 255, 255, .85)` }} bordered={false}>
                        <h2>
                            Type
                        </h2>
                        <div>
                            {this.props.islandType.type}
                        </div>
                    </Card>
                </Col>

                <Col style={{ display: "flex", flexDirection: "column" }} span={12}>
                    <Card style={{ flex: 1, background: `rgba(255, 255, 255, .85)` }} bordered={false}>
                        <h2>
                            Size
                        </h2>
                        <div>
                            {this.props.islandType.size}
                        </div>
                    </Card>
                </Col>

                <Col style={{ display: "flex", flexDirection: "column" }} span={24}>
                    <Card style={{ flex: 1, background: `rgba(255, 255, 255, .85)` }} bordered={false}>
                        <h2>
                            Features
                        </h2>
                        <div>
                            {this.props.islandType.features}

                        </div>
                    </Card>
                </Col>

                <Col style={{ display: "flex", flexDirection: "column" }} span={24}>
                    <Card style={{ flex: 1, background: `rgba(255, 255, 255, .85)` }} bordered={false}>
                        <h2>
                            Description
                        </h2>
                        <div>
                            {this.props.islandType.description}

                        </div>
                    </Card>
                </Col>

                <Col style={{ display: "flex", flexDirection: "column" }} span={24}>
                    <Card style={{ flex: 1, background: `rgba(255, 255, 255, .85)` }} bordered={false}>
                        <h2>
                            Examples
                        </h2>
                        <div>
                            {
                                this.props.islandType.examples?.length > 0 ?
                                    this.props.islandType.examples.map(island => {
                                        return (
                                            <Tag>
                                                {island}
                                            </Tag>
                                        )
                                    })
                                    :
                                    <>-</>
                            }
                        </div>
                    </Card>
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                {this.renderIslandType()}
            </div>
        )
    }
}

export default FlowchartResult