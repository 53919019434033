import { combineReducers } from "redux"

import notificationReducer from "./notificationReducer"
import energyTransitionReducer from "./energyTransitionReducer"
import islandTypologyReducer from "./islandTypologyReducer"
import greenCrusaderReducer from "./greenCrusaderReducer"

const INIT_STATE = {}

const rootReducer = (state = INIT_STATE, action) => {
    return appReducer(state, action)
}

const appReducer = combineReducers({
    notifications: notificationReducer,
    energyTransition: energyTransitionReducer,
    islandTypology: islandTypologyReducer,
    greenCrusader: greenCrusaderReducer
})

export default rootReducer
