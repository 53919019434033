import React from "react"
import { Button, Card, Col, Row } from "antd"

const AnswerType = {
    YES: "yes",
    NO: "no"
}

class Flowchart extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            chart: this.props.flowchart.chart,
            islandType: null
        }
    }

    renderQuestion = () => {
        return (
            <h3 style={{ textAlign: 'center' }}>
                {this.state.chart.question}
            </h3>
        )
    }

    onAnswerClicked = answer => {
        switch (answer) {
            case AnswerType.YES:
                if (this.state.chart.yes_target) {
                    this.setState({ chart: this.state.chart.yes_target })
                } else if (this.state.chart.yes_island_type) {
                    this.setState({ islandType: this.state.chart.yes_island_type })
                    this.props.setIslandTypeResult(this.state.chart.yes_island_type)
                }
                break
            case AnswerType.NO:
                if (this.state.chart.no_target) {
                    this.setState({ chart: this.state.chart.no_target })
                } else if (this.state.chart.no_island_type) {
                    this.setState({ islandType: this.state.chart.no_island_type })
                    this.props.setIslandTypeResult(this.state.chart.no_island_type)
                }
                break
            default:
                console.log("Not implemented.")
                break
        }
    }

    renderAnswers = () => {
        return (
            <Row>
                <Col span={12} style={{ textAlign: 'center' }}>
                    <Button onClick={() => this.onAnswerClicked(AnswerType.YES)}>
                        Yes
                    </Button>
                </Col>
                <Col span={12} style={{ textAlign: 'center' }}>
                    <Button onClick={() => this.onAnswerClicked(AnswerType.NO)}>
                        No
                    </Button>
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <div
                style={{
                    paddingLeft: '20%', paddingRight: '20%', marginTop: '32px'
                }}>
                <Card bordered={false} style={{
                    background: `rgba(255, 255, 255, .85)`,

                }}>
                    {this.renderQuestion()}
                    {this.renderAnswers()}
                </Card>
            </div>
        )
    }
}

export default Flowchart