import { unauthorizedApi } from "../api/api"
import { ENERGY_TRANSITION_ACTIONS } from "./types"


export const getEnergyTranisionActions = () => dispatch => {
    unauthorizedApi.get('energy-transition/actions').then(response => {
        dispatch({
            type: ENERGY_TRANSITION_ACTIONS,
            payload: {
                actions: response.data
            }
        })
    }).catch(error => console.log(error))
}