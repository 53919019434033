import React from "react"
import { connect } from "react-redux"
import { ConfigProvider } from "antd"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import LandingScreenAll from "./screens/landing/LandingScreenAll"
import LandingScreenMonitorTools from "./screens/landing/LandingScreenMonitorTools"
import IslandTypology from "./screens/IslandTypology"
import EnergyTransition from "./screens/EnergyTransition"
import GreenCrusader from "./screens/GreenCrusader"
import { primaryColor } from "./colors"
import { notificationListener } from "./utils/notification"
import NavigationWrapper from "./components/NavigationWrapper"

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <LandingScreenAll />
    ),
  },
  {
    path: "/monitor-tools",
    element: (
      <LandingScreenMonitorTools />
    ),
  },
  {
    path: "/island-typology",
    element: (
      <NavigationWrapper>
        <IslandTypology />
      </NavigationWrapper>
    ),
  },
  {
    path: "/energy-transition",
    element: (
      <NavigationWrapper>
        <EnergyTransition />
      </NavigationWrapper>
    ),
  },
  {
    path: "/green-crusader",
    element: (
      <GreenCrusader />
    ),
  },
  {
    path: "*",
    element: <LandingScreenAll />,
  },
])

class Root extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Helvetica Neue",
              colorPrimary: primaryColor,
              fontSize: 13,
              lineWidth: 1.4,
              colorText: primaryColor,
              colorInfo: primaryColor,
              lineWidth: 1.2,
            },
            components: {
              Input: {
                colorPrimary: primaryColor,
              },
              Radio: {
                colorPrimary: primaryColor,
              },
              Button: {
                colorPrimary: primaryColor,
              },
              Card: {
                colorPrimary: primaryColor,
              },
              Tabs: {
                colorPrimary: primaryColor,
              },
              Modal: {
                colorPrimary: primaryColor,
              },
              Switch: {
                colorPrimary: primaryColor,
              },
              Table: {
                colorPrimary: primaryColor,
              },
            },
          }}
        >
          <div
            style={{
              // minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {notificationListener()}
            {this.renderNavigation()}
          </div>
        </ConfigProvider>
      </>
    )
  }

  renderNavigation = () => {
    return <RouterProvider router={router} />
  }
}

const mapStateToProps = (state) => {
  return {
    notification: state.notifications.notification,
  }
}

export default connect(mapStateToProps, {})(Root)
