import { unauthorizedApi } from "../api/api"
import { showNotification } from "./notificationAction"
import { GREEN_CRUSADER_FINISHED, GREEN_CRUSADER_CLEAN } from "./types"
import { saveAs } from "file-saver"

export const registerGreenCrusader = (form) => dispatch => {
    unauthorizedApi.post('green-crusader/register', form,
        {
            responseType: "arraybuffer",
        }
    ).then(response => {
        dispatch({
            type: GREEN_CRUSADER_FINISHED,
            payload: {
                crusader: true
            }
        })
        saveAs(
            new Blob([response.data], { type: "application/pdf" }),
            `${form.complete_name}-certificate.pdf`
        )
        dispatch(showNotification("You have become a Green Crusader\n Congrats!", "The future is green, and we can do it together."))
    }).catch(error => {
        dispatch({
            type: GREEN_CRUSADER_FINISHED,
            payload: {
                crusader: error.response.data
            }
        })
        dispatch(showNotification("An error occured", "Please try again later..", "error"))
    })
}

export const cleanRegisterGreenCrusader = () => dispatch => {
    dispatch({
        type: GREEN_CRUSADER_CLEAN
    })
}
