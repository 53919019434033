import React from "react";
import { BarChart, Bar, YAxis, Legend, Label } from 'recharts';
import { greeceColor, italyColor, spainColor } from "../colors";
import { Card } from "antd";


class BarDiagram extends React.Component {

    getData = () => {
        return [
            {
                name: 'Spain', Spain: this.props.action.ap_in_spain
            },
            {
                name: 'Greece', Greece: this.props.action.ap_in_greece
            },
            {
                name: 'Italy', Italy: this.props.action.ap_in_italy
            }
        ]
    }

    render() {
        return (
            <Card title="Number of Action Plans" style={{ padding: '0.5%' }}>
                <BarChart width={400} height={200} data={this.getData()} style={{ zIndex: 999 }}>
                    <YAxis>
                        <Label angle={-90} value="Action Plans" offset={5} position={'center'} />
                    </YAxis>
                    <Legend width={100} wrapperStyle={{ top: 40, right: -250, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} />
                    <Bar dataKey="Spain" barSize={100} fill={spainColor} />
                    <Bar dataKey="Greece" barSize={100} fill={greeceColor} />
                    <Bar dataKey="Italy" barSize={100} fill={italyColor} />
                </BarChart>
            </Card>
        )
    }
}

export default BarDiagram
