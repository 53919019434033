import { Button, Card, Col, Modal, Row, Statistic } from "antd";
import React from "react";
import BarDiagram from "./BarDiagram";
import CountUp from 'react-countup';

const formatter = (value, decimalPlaces = 2) => <CountUp decimal="." end={value} duration={2.5} separator="," decimals={decimalPlaces} />;

class ActionDisplayModal extends React.Component {

    renderCardStatistic = (title, value, precision = 0, suffix = <></>, prefix = <></>) => {
        return <Card style={{ flex: 1 }} bordered={false}>
            <Statistic
                title={title}
                value={value}
                precision={precision}
                valueStyle={{
                    color: '#3f8600',
                }}
                suffix={suffix}
                prefix={prefix}
                formatter={(value) => formatter(value, precision)}
            />
        </Card>
    }

    render() {
        return (
            <Modal
                width={"90%"}
                footer={[
                    <Button key="ok" type="primary" onClick={this.props.closeModal}>
                        Back
                    </Button>,
                ]}
                open={true}
                onCancel={() => this.props.closeModal()}
                onOk={() => this.props.closeModal()}
            >
                <Row gutter={[20, 20]}>
                    <Col style={{ display: "flex", flexDirection: "column" }} span={12}>
                        <Card style={{ flex: 1 }} bordered={false}>
                            <h2>
                                Sector
                            </h2>
                            <div>
                                {this.props.action.sector}
                            </div>
                        </Card>
                    </Col>

                    <Col style={{ display: "flex", flexDirection: "column" }} span={12}>
                        <Card style={{ flex: 1 }} bordered={false}>
                            <h2>
                                Action
                            </h2>
                            <div>
                                {this.props.action.description}
                            </div>
                        </Card>
                    </Col>
                </Row>

                <Card bordered={false} style={{ marginTop: '24px' }}>
                    <BarDiagram action={this.props.action} />
                </Card>

                <Row gutter={[20, 20]} style={{ marginTop: '24px' }}>
                    <Col style={{ display: "flex", flexDirection: "column" }} span={8}>
                        {this.renderCardStatistic('Energy Savings', this.props.action.energy_savings, 0, 'MWH/year')}
                    </Col>
                    <Col style={{ display: "flex", flexDirection: "column" }} span={8}>
                        {this.renderCardStatistic('CO2 Savings', this.props.action.co2_savings, 1, 'tCO2e')}
                    </Col>
                    <Col style={{ display: "flex", flexDirection: "column" }} span={8}>
                        {this.renderCardStatistic('Cost', this.props.action.cost, 0, '€')}
                    </Col>
                </Row>

            </Modal>
        )
    }
}

export default ActionDisplayModal
