// Notification
export const NOTIFICATION_CLEAN = "NOTIFICATION_CLEAN"
export const NOTIFICATION_SHOW = "NOTIFICATION_SHOW"

// Energy tranisition
export const ENERGY_TRANSITION_ACTIONS = "ENERGY_TRANSITION_ACTIONS"

// Island typology
export const ISLAND_TYPOLOGY_TYPES = "ISLAND_TYPOLOGY_TYPES"
export const ISLAND_TYPOLOGY_CHARTS = "ISLAND_TYPOLOGY_CHARTS"

// Green crusader
export const GREEN_CRUSADER_CLEAN = "GREEN_CRUSADER_CLEAN"
export const GREEN_CRUSADER_FINISHED = "GREEN_CRUSADER_FINISHED"