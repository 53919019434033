import { ENERGY_TRANSITION_ACTIONS } from "../actions/types"

const INIT_STATE = {}

const energyTransitionReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ENERGY_TRANSITION_ACTIONS:
            return {
                ...state,
                actions: action.payload.actions,
            }
        default:
            return state
    }
}

export default energyTransitionReducer
