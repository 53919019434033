import React from "react"
import HomeButton from "./HomeButton";


class NavigationWrapper extends React.Component {
    render() {
        return (
            <>
                <HomeButton />
                {this.props.children}
            </>
        )
    }
}

export default NavigationWrapper