import { Col, Row, Card, Image } from "antd"
import React from "react"
import { NavLink } from "react-router-dom"
import { primaryColor } from "../../colors"
import { LOGO_FILE, MAIN_SITE_URL, IMAGE_NUMBER_ONE, IMAGE_NUMBER_TWO, IMAGE_NUMBER_THREE } from "../../config"

class LandingScreenAll extends React.Component {


    render() {
        return (
            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                <Row style={{ textAlign: 'center' }}>
                    <Col span={24}>

                        <h1 style={{ cursor: 'pointer', color: primaryColor }} onClick={() => window.location.href = MAIN_SITE_URL}>
                            <Image
                                width={80}
                                preview={false}
                                src={LOGO_FILE}
                            />
                            {/* Life Genera */}
                        </h1>
                    </Col>
                </Row>

                <Row gutter={[20, 20]}>
                    <Col span={8}>
                        <NavLink to={'/island-typology'}>
                            <Card
                                style={{
                                    height: '100vh',
                                    textAlign: 'center',
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),url(${IMAGE_NUMBER_ONE})`,
                                    backgroundSize: "cover"
                                }}
                                bordered={false}>
                                <h1 style={{ color: 'white', fontSize: 32 }}>
                                    Island Typology
                                </h1>
                            </Card>
                        </NavLink>
                    </Col>

                    <Col span={8}>
                        <NavLink to={'/energy-transition'}>
                            <Card
                                style={{
                                    height: '100vh',
                                    textAlign: 'center',
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),url(${IMAGE_NUMBER_TWO})`,
                                    backgroundSize: "cover"
                                }}
                                bordered={false}>
                                <h1 style={{ color: 'white', fontSize: 32 }}>
                                    Energy Transition
                                </h1>
                            </Card>
                        </NavLink>
                    </Col>


                    <Col span={8}>
                        <NavLink to={'green-crusader'}>
                            <Card
                                style={{
                                    height: '100vh',
                                    textAlign: 'center',
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),url(${IMAGE_NUMBER_THREE})`,
                                    backgroundSize: "cover"
                                }}
                                bordered={false}>

                                <h1 style={{ color: 'white', fontSize: 32 }}>
                                    Become a Green Crusader
                                </h1>
                            </Card>
                        </NavLink>
                    </Col>
                </Row>

            </div>
        )
    }
}

const styles = {
    navigationItem: {
        textAlign: 'center'
    }
}

export default LandingScreenAll