import { Card, Col, Row } from "antd"
import React from "react"


class FlowchartList extends React.Component {

    onFlowchartSelected = (chart) => {
        this.props.onFlowchartSelected(chart)
    }

    renderFlowchartOptions = () => {
        return this.props.charts?.map(chart => {
            return <Col span={8}>
                <Card

                    style={{
                        cursor: 'pointer',
                        background: `rgba(255, 255, 255, .85)`
                    }}
                    onClick={() => this.onFlowchartSelected(chart)}
                    bordered={false}>
                    <h2>
                        {chart.category}
                    </h2>
                    <p>
                        {chart.description}
                    </p>
                </Card>
            </Col>
        })
    }

    render() {
        return (
            <Row gutter={[20, 20]}>
                {this.renderFlowchartOptions()}
            </Row>
        )
    }
}

export default FlowchartList