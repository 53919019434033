import { Button, Card, Checkbox, Col, Divider, Form, Image, Input, Row } from "antd"
import React from "react"
import { primaryColor } from "../colors"
import { connect } from "react-redux"
import { cleanRegisterGreenCrusader, registerGreenCrusader } from "../actions/greenCrusaderAction"
import { IMAGE_NUMBER_THREE, LOGO_FILE, MAIN_SITE_URL } from "../config"


class GreenCrusader extends React.Component {
    formRef = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            fullName: "",
            country: "",
            email: "",
            gdpr: false,
            loading: false
        }
    }

    isFormReady = () => {
        return !(
            this.state.fullName && this.state.country && this.state.email && this.state.gdpr
        )
    }

    componentDidUpdate() {
        if (this.state.loading && this.props.registerFinished) {
            this.props.cleanRegisterGreenCrusader()
            this.setState({ loading: false, gdpr: false })
            this.formRef.current.resetFields()
        }
    }

    becomeGreenCrusaderButtonClicked = () => {
        this.setState({ loading: true })
        this.props.registerGreenCrusader({
            complete_name: this.state.fullName,
            country: this.state.country,
            email: this.state.email
        })
    }

    renderForm = () => {
        return (
            <Form ref={this.formRef}>
                <Form.Item
                    labelCol={{ span: 24 }}
                    name="fullName"
                    label="Full name"
                    rules={[

                        {
                            required: true,
                            message: 'Please input your full name',
                        },
                    ]}>
                    <Input maxLength={512} onChange={(e) => this.setState({ fullName: e.target.value })} />
                </Form.Item>

                <Form.Item
                    labelCol={{ span: 24 }}
                    name="country"
                    label="Country"
                    rules={[

                        {
                            required: true,
                            message: 'Please input country',
                        },
                    ]}>
                    <Input maxLength={512} onChange={(e) => this.setState({ country: e.target.value })} />
                </Form.Item>

                <Form.Item
                    validateDebounce={1000}
                    labelCol={{ span: 24 }}
                    name="email"
                    label="E-mail"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        },
                        {
                            required: true,
                            message: 'Please input your E-mail',
                        },
                    ]}>
                    <Input maxLength={254} onChange={(e) => this.setState({ email: e.target.value })} />
                </Form.Item>

                <Form.Item>
                    <Checkbox checked={this.state.gdpr} onChange={(e) => this.setState({ gdpr: e.target.checked })} />
                    <span style={{ marginLeft: '8px' }}>
                        I agree to be part of the Green Crusaders' group, supporting the actions
                        carried out within the framework of the Genera project that ensures the
                        energy transition on tourist islands.
                    </span>
                </Form.Item>

                <Button
                    loading={this.state.loading}
                    onClick={this.becomeGreenCrusaderButtonClicked}
                    disabled={this.isFormReady()}>
                    Become a Green Crusader
                </Button>

                <p>
                    *Disclaimer: the information collected will be treated confidentially and will
                    not be used for any other purpose.
                </p>
            </Form>
        )
    }

    render() {
        return (
            <div
                style={{
                    height: '100vh',
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),url(${IMAGE_NUMBER_THREE})`,
                    backgroundSize: 'cover',
                    paddingLeft: '20%',
                    paddingRight: '20%',
                }}
            >
                <Divider>
                    <h1 style={{ color: 'white' }}>Green Crusader</h1>
                </Divider>

                <Row style={{ textAlign: 'center' }}>
                    <Col span={24}>

                        <h1 style={{ cursor: 'pointer', color: primaryColor }} onClick={() => window.location.href = MAIN_SITE_URL}>
                            <Image
                                width={80}
                                preview={false}
                                src={LOGO_FILE}
                            />
                            {/* Life Genera */}
                        </h1>
                    </Col>
                </Row>

                <Card
                    style={{
                        background: `rgba(255, 255, 255, .9)`
                    }}
                    bordered={false}
                >

                    <p style={{ fontSize: "16px", textAlign: 'center', color: 'black', paddingLeft: "16%", paddingRight: "16%" }}>
                        Become a member of the <span style={{ color: primaryColor }}>Green Crusaders’</span> group which is populated by representatives of the local authorities and universities or other actors that are engaged in <span style={{ color: primaryColor }}>GENERA's</span> energy transition approach across the islands.


                        <br />
                    </p>

                    <div style={{ paddingLeft: '15%', paddingRight: '15%' }}>
                        {this.renderForm()}
                    </div>


                    <p style={{ textAlign: 'center', color: 'black', paddingLeft: "16%", paddingRight: "16%" }}>
                        <br />
                        The future is <span style={{ color: primaryColor }}>green</span>, and we can do it together.
                    </p>


                </Card>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        registerFinished: state.greenCrusader.registerFinished
    }
}

export default connect(mapStateToProps, { registerGreenCrusader, cleanRegisterGreenCrusader })(GreenCrusader)