import { ISLAND_TYPOLOGY_TYPES, ISLAND_TYPOLOGY_CHARTS } from "../actions/types"

const INIT_STATE = {}

const islandTypologyReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ISLAND_TYPOLOGY_TYPES:
            return {
                ...state,
                types: action.payload.types,
            }
        case ISLAND_TYPOLOGY_CHARTS:
            return {
                ...state,
                charts: action.payload.charts,
            }
        default:
            return state
    }
}

export default islandTypologyReducer
