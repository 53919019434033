import { unauthorizedApi } from "../api/api"
import { ISLAND_TYPOLOGY_TYPES, ISLAND_TYPOLOGY_CHARTS } from "./types"

export const getIslandTypologyTypes = () => dispatch => {
    unauthorizedApi.get('island-typology/types').then(response => {
        dispatch({
            type: ISLAND_TYPOLOGY_TYPES,
            payload: {
                types: response.data
            }
        })
    }).catch(error => console.log(error))
}

export const getIslandTypologyCharts = () => dispatch => {
    unauthorizedApi.get('island-typology/charts').then(response => {
        dispatch({
            type: ISLAND_TYPOLOGY_CHARTS,
            payload: {
                charts: response.data
            }
        })
    }).catch(error => console.log(error))
}