import { NOTIFICATION_CLEAN, NOTIFICATION_SHOW } from "./types"

export const cleanNotification = () => (dispatch) => {
    dispatch({
        type: NOTIFICATION_CLEAN,
        payload: {
            notification: null,
        },
    })
}

export const showNotification =
    (title, message, status = "success") =>
        (dispatch) => {
            dispatch({
                type: NOTIFICATION_SHOW,
                payload: {
                    notification: {
                        title: title,
                        description: message,
                        status: status,
                    },
                },
            })
        }
